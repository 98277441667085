<template>
    <div class="container top-0 position-sticky z-index-sticky">
        <div class="row">
            <div class="col-12">
                <navbar
                    is-blur="blur border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow"
                    btn-background="bg-gradient-success"
                    :dark-mode="true"
                />
            </div>
        </div>
    </div>
    <main class="main-content main-content-bg mt-0">
        <div class="page-header min-vh-100" style="background-color: #d6e4e5">
            <span class="mask opacity-6"></span>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-4 col-md-7 mt-2">
                        <div class="card border-0 mb-0">
                            <div class="card-header bg-transparent text-center">
                                <img
                                    src="../assets/logo_new.webp"
                                    alt="logo"
                                    style="width: 70px; height: auto"
                                />
                            </div>
                            <div class="card-body px-lg-3 pt-0">
                                <div
                                    class="text-center fw-bold"
                                    style="font-size: 15px"
                                    v-if="products.length < 1"
                                >
                                    ไม่มีข้อมูลออเดอร์นี้
                                </div>
                                <div
                                    style="font-size: 15px"
                                    v-if="order.type === 'ส่งผ่านไรเดอร์'"
                                >
                                    ส่งผ่านไรเดอร์ ({{ order.branch }})
                                    <br />
                                    สถานที่ส่ง: {{ order.place || "" }}
                                    <br />
                                    หมายเหตุ:
                                    <br />
                                    โซน:
                                    <br />
                                    <br />
                                    เบอร์โทรศัพท์: {{ order.phone_number }}
                                    <br />
                                    <br />
                                    <div
                                        v-for="(item, index) in products"
                                        :key="index"
                                    >
                                        {{ item.product }}<br />
                                    </div>
                                    <br />
                                    จำนวนสินค้า: {{ order.total }} ชิ้น
                                    <br />
                                    <div v-if="order.discount != 0">
                                        ยอดส่วนลด: {{ order.discount }}฿
                                        <br />
                                    </div>
                                    ยอดสินค้า: {{ order.price }}฿
                                    <br />
                                    ยอดค่าส่ง:
                                    <br />
                                    <br />
                                    รวมทั้งหมด:
                                    <br />
                                    การชำระเงิน: {{ order.payment }}
                                </div>
                                <div
                                    style="font-size: 15px"
                                    v-if="order.type === 'ส่งผ่านพัสดุ'"
                                >
                                    ส่งผ่านพัสดุ
                                    <br />
                                    <br />
                                    ที่อยู่:
                                    <br />
                                    <div
                                        v-html="formattedText(order.address)"
                                    ></div>
                                    <br />
                                    <div
                                        v-for="(item, index) in products"
                                        :key="index"
                                    >
                                        {{ item.product }}<br />
                                    </div>
                                    <br />
                                    จำนวนสินค้า: {{ order.total }} ชิ้น
                                    <br />
                                    <div v-if="order.discount != 0">
                                        ยอดส่วนลด: {{ order.discount }}฿
                                        <br />
                                    </div>
                                    ยอดสินค้า: {{ order.price }}฿
                                    <br />
                                    ยอดค่าส่ง: {{ getDeliveryPrice(order.total) }}฿
                                    <br />
                                    <br />
                                    รวมทั้งหมด: {{ order.price + getDeliveryPrice(order.total) }}฿
                                    <br />
                                    การชำระเงิน: {{ order.payment }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
    <app-footer />
</template>

<script>
import axios from "../services/axios";

export default {
    name: "OrderPage",
    components: {},
    data() {
        return {
            order: {},
            products: [],
            uniqueTotal: 0,
        };
    },
    created() {
        axios
            .get(`/order/${this.$route.params.id}`)
            .then((resp) => {
                if (resp?.data?.status === "success") {
                    this.order = resp?.data?.order;
                    this.products = resp?.data?.products;
                    this.uniqueTotal = resp?.data?.uniqueTotal
                }
            })
            .catch((err) => {
                if (err?.response) {
                    if (
                        err?.response?.status !== 500 &&
                        err?.response?.status !== 404
                    ) {
                        this.$swal({
                            text: err?.response?.data?.message
                                ? err?.response?.data?.message
                                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่",
                            icon: "error",
                        });
                    }
                }
            });
    },
    methods: {
        getDeliveryPrice(total) {
            let price = 0
            let products = total - this.uniqueTotal;
            let productsPrice = products * 0.6;
            let uniqueTotalPrice = this.uniqueTotal * 1.5;

            if (productsPrice + uniqueTotalPrice > 40) {
                price = Math.ceil(productsPrice + uniqueTotalPrice)
            } else {
                price = 40
            }

            return price
        },
        formattedText(text) {
            return text.replace(/\n/g, "<br>");
        },
    },
};
</script>
